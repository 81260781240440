<template>
    <main>
        <div :class="calloutClass">
            <h4>
                <i :class="icon" aria-hidden="true"></i>
                {{ titleText }}
            </h4>
            <span v-html="message"></span>
        </div>
    </main>
</template>

<script>
export default {
    name: "callout",
    props: {
        message: {
            type: String,
            default: '',
            required: true
        },
        title: {
            type: String,
            required: false,
            default: 'Error'
        },
        alertType:
            {
                type: String,
                required: false,
                default: 'error'
            }
    },
    computed: {
        calloutClass: function () {
            let base = "mt-0 bg-white bd-callout bd-callout-";
            switch (this.alertType) {
                case "information":
                    return base + "info";
                case "warning":
                    return base + "warning";
            }

            return base + "danger";
        },
        icon: function () {
            let base = "fa-duotone fa-";
            switch (this.alertType) {
                case "information":
                    return base + "circle-info";
                case "warning":
                    return base + "triangle-exclamation";
            }

            return base + "circle-exclamation";
        },
        titleText: function () {
            return this.title === 'Error' ? this.alertType.charAt(0).toUpperCase() + this.alertType.slice(1) : this.title;
        }
    }
}
</script>
